export default {
    computed: {
        $_congressId() {
            return this.$route.params.congressId;
        },
        $_modalId() {
            return this.$route.params.modalId;
        },
        $_detailId() {
            return this.$route.params.detailId;
        }
    }
}