import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "./plugins/axios";
import VueFeather from 'vue-feather';

[axios, VueFeather].forEach((x) => Vue.use(x));

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
