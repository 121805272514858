<template>
  <div class="suggested-course-wrapper">
    <div
      v-for="(course, index) in courses"
      :key="course.id"
      class="suggested-course"
      @mouseover="currentIndex = index"
      @mouseleave="currentIndex = -1"
      @click="gotSuggestedVideo(course)"
    >
      <div v-if="course.id !== $_detailId">
        <div class="suggested-course__image">
          <div
            v-if="checkThumbnail(course)"
            class="image"
            :style="{
              backgroundImage: 'url(' + course.virkon_thumbnail_url + ')',
            }"
          />
          <div
            v-else
            class="image"
            :style="getImageBackound(course, index)"
          />
        </div>
        <div class="suggetst-course__name">
          <span>{{ course.nazev }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routeParamMixin from "../mixins/routeParamMixin";

export default {
  name: "suggested-courses",
  mixins: [routeParamMixin],

  data() {
    return {
      hasError: false,
      courses: [],
      currentIndex: -1,
    };
  },
  async mounted() {
    await this.fetchVideo();
  },
  methods: {
    async fetchVideo() {
      try {
        ({ data: this.courses } = await this.$axios.get(
          `https://savana.medicinskevzdelavani.cz/virkon/api/courses/conference/${this.$_congressId}`,
          {
            headers: {
              "Api-Key": "5df40322-58d0-4dfb-9997-dda9d639e2db",
            },
          }
        ));
        this.courses.sort((a,b) => {
          if (a.id > b.id) {
            return -1
          } else {
            return 1
          }
        });
      } catch {
        this.hasError = true;
      }
    },
    getImageBackound(course) {
      let imageUrl = `https://vz-b3790b7d-a8a.b-cdn.net/${course.bunny_video_id}/thumbnail.jpg`;
      return `background-image: url(${imageUrl})`;
    },
    gotSuggestedVideo(course) {
      this.$router.push({
        name: "Course-detail",
        params: { detailId: course.id },
      });
    },
    checkThumbnail(course) {
      return course.virkon_thumbnail_url.indexOf('thumbnail') >= 0;
    }
  },
};
</script>

<style lang="scss" scoped>
.suggested-course-wrapper {
  width: 23%;
  margin-top: 2.4rem;

  .suggested-course {
    width: 100%;
    cursor: pointer;
    
    .suggested-course__image {
      height: 11rem;

      .image {
        width: 100%;
        min-height: 10rem;
        vertical-align: middle;
        background-size: cover;
        background-position: center;
      }
    }

    .suggetst-course__name {
      color: white;
      margin-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 1000px) {
  .suggested-course-wrapper {
    width: 100%;
  }
}
</style>