<template>
  <div class="courses">
    <div id="courses-wrapper">
      <loading-circle v-if="loadingPage" />
      <courses
        v-if="!loadingPage"
        :items="courses"
        :read-more-text="'Zobrazit kurz'"
        @clicked="goToDetail"
      />
      <error-page v-if="hasError" />
    </div>
  </div>
</template>

<script>
import courses from "../../components/courses.vue";
import errorPage from "../../components/errorPage.vue";
import loadingCircle from "../../components/loadingCircle.vue";
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { errorPage, loadingCircle, courses },
  mixins: [routeParamMixin],

  data() {
    return {
      hasError: false,
      loadingPage: false,
      bgChanged: false,
      courses: null,
      token: null,
      login: {
        email: "blazicek@effecto.cz",
        password: "Test123",
      },
    };
  },
  async mounted() {
    await this.fetchCourses();
  },
  methods: {
    async fetchCourses() {
      try {
        this.loadingPage = true;
        ({ data: this.courses } = await this.$axios.get(
          `https://savana.medicinskevzdelavani.cz/virkon/api/courses/conference/${this.$_congressId}`,
          {
            headers: {
              "Api-Key": "5df40322-58d0-4dfb-9997-dda9d639e2db",
            },
          }
        ));
        this.courses.sort((a,b) => {
          if (a.id > b.id) {
            return -1
          } else {
            return 1
          }
        });
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
    goToDetail(value) {
      this.$router.push({ name: "Course-detail", params: { detailId: value } });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variarbles.scss";

.courses {
  min-height: 100vh;
  background-color: $dark-bg;
  padding: 4rem;

  #courses-wrapper {
    max-width: 95rem;
    margin: auto;
  }
}

@media screen and (max-width: 1000px) {
  .courses {
    padding: 1rem;
  }
}
</style>