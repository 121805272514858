<template>
  <div class="page-padding">
    <detail
      v-if="article"
      :routeBack="routeBack"
      :img="article.thumbnail_url"
      :title="article.name"
      :html="article.html"
      :error="hasError"
    />
    <loading-circle v-if="loadingPage" />
    <error-page v-if="hasError" />
  </div>
</template>

<script>
import detail from "../../components/detail.vue";
import errorPage from '../../components/errorPage.vue';
import loadingCircle from '../../components/loadingCircle.vue';
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { detail, loadingCircle, errorPage },
  mixins: [routeParamMixin],

  data() {
    return {
      routeBack: {
       routeName: "Articles",
       displayText: "Zpět na seznam novinek"
      },
      hasError: false,
      loadingPage: false,
      article: null,
    };
  },
  async mounted() {
    await this.fetchHtml();
  },
  methods: {
    async fetchHtml() {
      try {
        this.loadingPage = true;
        ({
          data: { article: this.article },
        } = await this.$axios(`/articles/${this.$_detailId}`));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
  },
};
</script>

<style>
</style>