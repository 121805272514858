<template>
  <div v-if="course" class="course-video">
    <div class="route-back-link">
      <a @click="$router.push({ name: 'Courses' })"
        ><feather type="arrow-left-circle" />Zpět na seznam kurzů</a
      >
    </div>
    <div v-if="course[0].bunny_video_id" class="course-video__wrapper">
      <iframe
        class="course-video__frame"
        :src="`https://iframe.mediadelivery.net/embed/1231/${course[0].bunny_video_id}?autoplay=false&preload=false`"
        loading="lazy"
        allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
        allowfullscreen="true"
      ></iframe>
    </div>
    <div v-else class="course-pdf__wrapper">
      <iframe
        class="course-pdf__frame"
        :src="`${course[0].prilohy[0].url}#toolbar=0&navpanes=0&scrollbar=1`"
        width="100%"
        height="500px"
      >
      </iframe>
    </div>
    <div v-if="course">
      <div class="course-video__title">
        <h2>{{ course[0].nazev }}</h2>
        <button
          v-if="course[0].pocet_kreditu > 0"
          @click="$emit('onStartTest')"
        >
          Spustit test
        </button>
      </div>
      <div v-if="!isDermacademy" class="course-video__credits">
        <div>ČLK kredity: {{ course[0].pocet_kreditu }}</div>
        <div>Kongresové kredity: {{ course[0].pocet_kreditu_virkon }}</div>
      </div>
      <div class="course-video__description">
        <p>
          {{ course[0].popis }}
        </p>
      </div>
      <button
        class="dermacademy-btn"
        v-if="isDermacademy"
        @click="linkTo('https://dermacademy.cz/')"
      >
        <feather size="20" type="home" /> Zpět na dermacademy.cz
      </button>
    </div>
  </div>
</template>

<script>
import routeParamMixin from "../mixins/routeParamMixin";

export default {
  name: "detail-course",
  mixins: [routeParamMixin],

  data() {
    return {
      hasError: false,
      course: null,
      testUrl: null,
      showTestModal: false,
    };
  },
  watch: {
    async $_detailId() {
      await this.fetchVideo();
    },
  },
  async mounted() {
    await this.fetchVideo();
    if (this.course[0].bunny_video_id) {
      this.$emit("showLoginModal");
    }
  },
  computed: {
    isDermacademy() {
      return this.$route.params.congressId === "3";
    },
  },
  methods: {
    linkTo(link) {
      open(link);
      console.log(this.$route.params.congressId);
    },
    async fetchVideo() {
      try {
        const courses = await this.$axios.get(
          `https://savana.medicinskevzdelavani.cz/virkon/api/courses/conference/${this.$_congressId}`,
          {
            headers: {
              "Api-Key": "5df40322-58d0-4dfb-9997-dda9d639e2db",
            },
          }
        );
        this.course = courses.data.filter(
          (course) => course.id == this.$_detailId
        );
      } catch {
        this.hasError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.course-video {
  width: 70%;

  .route-back-link {
    color: white;

    a {
      display: flex;
      align-items: center;
      color: white;

      ::v-deep.feather {
        margin-right: 0.5rem;
      }
    }
  }
  .dermacademy-btn {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    ::v-deep .feather--home {
      margin-right: 0.5rem;
    }

    @media screen and (max-width: 700px) {
      width: 100%;
      justify-content: center;
    }
  }

  .course-pdf__wrapper {
    position: relative;
    padding: 29%;
    margin-bottom: 2rem;
    .course-pdf__frame {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .course-video__wrapper {
    position: relative;
    padding: 29%;
    margin-bottom: 2rem;

    .course-video__frame {
      border: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .course-video__title {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    button {
      margin-left: 5rem;
    }

    h2 {
      color: white;
      margin-top: 0;
    }
  }

  .course-video__credits {
    color: white;
    div {
      margin-bottom: 0.5rem;
    }
  }

  .course-video__description {
    color: rgb(153, 153, 153);
    margin-top: 1.5rem;
  }
}

@media screen and (max-width: 1000px) {
  .course-video {
    width: 100%;
    .course-video__title {
      flex-direction: column;

      button {
        margin: 1rem 0 2rem 0;
      }
    }
  }
}
</style>
