<template>
  <div class="page-padding profile" v-if="user">
    <h1>{{ user.krestni_jmeno }} {{ user.prijmeni }}</h1>
    <div class="credits">ČLK kredity: {{ user.credits.clk }}</div>
    <div class="credits">Virkon kredity: {{ user.credits.virkon }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: null,
    };
  },
  async mounted() {
    await this.fetchUserInfo();
  },
  methods: {
    async fetchUserInfo() {
      try {
        const token = localStorage.getItem("token");
        ({ data: this.user } = await this.$axios.get(
          `https://savana.medicinskevzdelavani.cz/virkon/api/user`,
          {
            headers: {
              "Api-Key": "5df40322-58d0-4dfb-9997-dda9d639e2db",
              Authorization: `Bearer ${token}`,
            },
          }
        ));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.profile {
  margin: 1rem auto;
  max-width: 25rem;
  border: 1px solid gray;

  .credits {
    font-size: 1.2rem;
    margin: 0 0 1rem 0;
  }
}
</style>