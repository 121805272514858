<template>
  <div class="detail-wrapper">
    <div class="route-back-link">
      <feather type="arrow-left-circle" /><a
        @click="$router.push({ name: routeBack.routeName })"
        >{{ routeBack.displayText }}</a
      >
    </div>
    <img :src="img" alt="" />
    <h2>{{ title }}</h2>
    <div v-html="html" />
  </div>
</template>

<script>
export default {
  props: {
    routeBack: {
      type: Object,
    },
    img: {
      type: String,
    },
    title: {
      type: String,
    },
    html: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/index.scss";

.detail-wrapper {
  max-width: 35rem;
  margin: auto;

  h2 {
    margin-top: 0;
  }

  img {
    width: 100%;
    margin-bottom: 1rem;
  }

  ::v-deep .pdf-modal {
    width: 100%;
    background: white;
    object {
      width: 100%;
      height: 100vh;
      vertical-align: middle;
    }
  }
}
</style>