<template>
  <div class="page-padding">
    <detail
      v-if="publication"
      :routeBack="routeBack"
      :img="publication.thumbnail_url"
      :title="publication.name"
      :html="publication.html"
      :error="hasError"
    />
    <loading-circle v-if="loadingPage" />
    <error-page v-if="hasError" />
  </div>
</template>

<script>
import detail from "../../components/detail.vue";
import errorPage from '../../components/errorPage.vue';
import loadingCircle from '../../components/loadingCircle.vue';
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { detail, loadingCircle, errorPage },
  mixins: [routeParamMixin],

  data() {
    return {
      routeBack: {
       routeName: "Publications",
       displayText: "Zpět na seznam publikací"
      },
      hasError: false,
      loadingPage: false,
      publication: null,
    };
  },
  async mounted() {
    await this.fetchHtml();
  },
  methods: {
    async fetchHtml() {
      try {
        this.loadingPage = true;
        ({
          data: { publication: this.publication },
        } = await this.$axios(`/publications/${this.$_detailId}`));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .detail-wrapper {
  max-width: 100%;
}
</style>