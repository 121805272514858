<template>
  <div>
    <div v-if="htmlModal" v-html="htmlModal"></div>
    <loading-circle v-if="loadingPage" />
    <error-page v-if="hasError" />
  </div>
</template>

<script>
import errorPage from "../../components/errorPage.vue";
import loadingCircle from "../../components/loadingCircle.vue";
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { errorPage, loadingCircle },
  mixins: [routeParamMixin],
  data() {
    return {
      htmlModal: null,
      hasError: false,
      loadingPage: false,
    };
  },
  async mounted() {
    await this.fetchHtmlModal();
  },
  methods: {
    async fetchHtmlModal() {
      try {
        this.loadingPage = true;
        ({
          data: {
            html_modal: { html: this.htmlModal },
          },
        } = await this.$axios.get(
          `/congresses/${this.$_congressId}/modals/${this.$_modalId}/html`
        ));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .pdf-modal {
  background: white;
  object {
    width: 100%;
    height: 100vh;
    vertical-align: middle;
  }
}

::v-deep .video-modal {
  max-height: 100vh;
  text-align: center;
  video {
    max-height: 100vh;
    max-width: 100%;
  }
}
</style>