<template>
  <div class="page-padding">
    <h1>Publikace</h1>
    <loading-circle v-if="loadingPage" />
    <tiles :items="publications" @clicked="onTileClick" :read-more-text="'Zobrazit publikaci'" />
    <error-page v-if="hasError" />
  </div>
</template>

<script>
import tiles from "@/components/tiles";
import errorPage from "../../components/errorPage.vue";
import loadingCircle from "../../components/loadingCircle.vue";
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { tiles, errorPage, loadingCircle },
  mixins: [ routeParamMixin ],
  
  data() {
    return {
      publications: [],
      hasError: false,
      loadingPage: false,
    };
  },
  computed: {
    congressId() {
      return this.$route.params.congressId;
    },
  },
  mounted() {
    this.fetchPublications();
  },
  methods: {
    async fetchPublications() {
      try {
        this.loadingPage = true;
        ({
          data: {
            publications_modal: { publications: this.publications },
          },
        } = await this.$axios.get(`/congresses/${this.$_congressId}/modals/${this.$_modalId}/publications`));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
    onTileClick(value) {
      this.$router.push({ name: "Publication-detail", params: { congressId: this.congressId, detailId: value } });
    },
  },
};
</script>

<style>
</style>