import Vue from 'vue';
import VueRouter from 'vue-router';
import ArticleIndex from '../views/articles/index.vue';
import ArticleId from '../views/articles/_id.vue';
import PublicationsIndex from '../views/publications/index.vue';
import PublicationId from '../views/publications/_id.vue';
import BannerId from '../views/banner/_id.vue';
import CoursesIndex from '../views/courses/index.vue';
import CoursesId from '../views/courses/_id.vue';
import ProfileIndex from '../views/profile/index.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:congressId/articles/:modalId',
    name: 'Articles',
    component: ArticleIndex,
  },
  {
    path: '/:congressId/articles/:modalId/:detailId',
    name: 'Article-detail',
    component: ArticleId,
  },
  {
    path: '/:congressId/publications/:modalId',
    name: 'Publications',
    component: PublicationsIndex,
  },
  {
    path: '/:congressId/publications/:modalId/:detailId',
    name: 'Publication-detail',
    component: PublicationId,
  },
  {
    path: '/:congressId/banner/:modalId',
    name: 'Banner-detail',
    component: BannerId,
  },
  {
    path: '/:congressId/courses/1',
    name: 'Courses',
    component: CoursesIndex,
  },
  {
    path: '/:congressId/courses/1/:detailId',
    name: 'Course-detail',
    component: CoursesId,
  },
  {
    path: '/:congressId/profile',
    name: 'Profile',
    component: ProfileIndex,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
