<template>
  <div id="tiles">
    <div
      v-for="item in items"
      :key="item.name"
      class="tile"
      @click="$emit('clicked', item.id)"
    >
      <div class="tile__card">
        <div class="tile__image">
          <div class="image-placeholder" v-if="!item.thumbnail_url" />
          <div
            v-if="item.thumbnail_url"
            class="image"
            :style="'background-image: url(' + item.thumbnail_url + ')'"
            alt=""
          />
        </div>
        <div class="tile__text">
          <div class="tile__title">{{ item.name }}</div>
          <div class="tile__anotation">
            {{ item.annotation }}
          </div>
        </div>
        <div class="tile__read-more">
          {{ readMoreText }} <feather type="arrow-right" />
        </div>
        <div class="tile__footer">
          <div class="footer">
            <div>
              {{ formatDate(item.creation_ldt) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tiles",
  props: {
    items: {
      type: Array,
    },
    readMoreText: {
      type: String,
      default: "Přejít na detail",
    },
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return event.toLocaleDateString("cs-CS", options);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/index.scss";

#tiles {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .tile {
    padding: 0 12px;
    max-width: 20rem;
    margin-bottom: 2rem;

    .tile__card {
      cursor: pointer;
      border: 1px solid gainsboro;
      height: 25.5rem;
      overflow: hidden;
      position: relative;

      &:hover {
        .tile__read-more {
          opacity: 1;
        }
      }

      .image-placeholder {
        background: gainsboro;
        width: 100%;
        height: 11rem;
      }

      .tile__image {
        width: 100%;
        position: relative;
        .image {
          width: 100%;
          vertical-align: middle;
          height: 11rem;
          background-size: cover;
        }
      }

      .tile__text {
        padding: 1rem;

        .tile__title {
          font-size: 1.2rem;
          color: black;
          margin-bottom: 1rem;
        }

        .tile__anotation {
          max-width: 20rem;
          margin: 0.5rem 0;
          color: gray;
        }
      }

      .tile__read-more {
        background: white;
        height: 1rem;
        width: 90%;
        position: absolute;
        bottom: 2.5rem;
        z-index: 1;
        padding: 1.3rem 1rem;
        color: $primary;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 350ms linear;

        ::v-deep .feather .feather-arrow-right {
          width: 1.5rem;
          margin-left: 0.3rem;
          stroke-width: 0.1rem;
        }
      }

      .tile__footer {
        position: absolute;
        bottom: 0;
        background: $light-gray;
        width: 100%;

        .footer {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 1rem;
          color: gray;

          &::after {
            content: "";
            display: block;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0) 0,
              rgba(255, 255, 255, 1) 75%
            );
            width: 100%;
            height: 4rem;
            position: absolute;
            top: -4rem;
            left: 0;
          }
        }
      }
    }
  }
}
</style>
