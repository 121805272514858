<template>
  <div class="course">
    <loading-circle v-if="loadingPage" />
    <div id="course-wrapper">
      <login-modal :showLoginModal="showLoginModal" @userLogin="userLogin" />
      <detail-course @onStartTest="startTest" @showLoginModal="checkToken" />
      <suggested-courses />
    </div>
  </div>
</template>

<script>
import detailCourse from "../../components/detailCourse.vue";
import loadingCircle from "../../components/loadingCircle.vue";
import SuggestedCourses from "../../components/suggestedCourses.vue";
import routeParamMixin from "../../mixins/routeParamMixin";
import LoginModal from "../../components/loginModal.vue";

export default {
  name: "course-detail",
  mixins: [routeParamMixin],
  components: {
    loadingCircle,
    detailCourse,
    SuggestedCourses,
    LoginModal,
  },

  data() {
    return {
      hasError: false,
      loadingPage: false,
      showTestModal: false,
      showLoginModal: false,
      redirectToTest: false,
    };
  },
  watch: {
    showLoginModal() {
      this.showLoginModal
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
  },
  methods: {
    startTest() {
      const token = localStorage.getItem("token");
      if (token) {
        window.open(
          `https://savana.medicinskevzdelavani.cz/testy/?vyplnit&precteno&id=${this.$_detailId}&virkon_token=${token}`
        );
      } else {
        this.showLoginModal = true;
        this.redirectToTest = true;
      }
    },
    closeIframeModal() {
      document.body.classList.remove("no-scroll");
      this.showTestModal = false;
    },
    checkToken() {
      const token = localStorage.getItem("token");
      this.showLoginModal = !token;
    },
    userLogin() {
      const token = localStorage.getItem("token");
      this.showLoginModal = false;
      if (this.redirectToTest) {
        window.open(
          `https://savana.medicinskevzdelavani.cz/testy/?vyplnit&precteno&id=${this.$_detailId}&virkon_token=${token}`
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variarbles.scss";

.course {
  background: $dark-bg;
  min-height: 100vh;
  position: relative;

  #course-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 80rem;
    margin: auto;
    padding-top: 2rem;
  }

  .iframe-modal-wrapper {
    top: 0;
    left: 0;
    background-color: rgba(12, 12, 12, 0.521);
    backdrop-filter: blur(5px);

    ::v-deep .iframe-modal {
      top: 5%;
      height: 70%;
      transform: translateX(-50%);
    }
  }
}

@media screen and (max-width: 1000px) {
  .course {
    #course-wrapper {
      flex-direction: column;
      padding: 1rem;
    }
  }
}
</style>
