<template>
  <div v-if="items" id="tiles">
    <div class="header" v-if="items.length > 0">
      <h1>E-learningové kurzy</h1>
      <button v-if="isDermacademy" @click="linkTo('https://dermacademy.cz/')">
        <feather size="20" type="home" /> Zpět na dermacademy.cz
      </button>
    </div>
    <div v-else class="header-link">
      <a href="https://www.medicinskevzdelavanie.sk/" target="_blank"
        ><h1>www.medicinskevzdelavanie.sk</h1></a
      >
    </div>
    <div
      v-for="(item, index) in items"
      :key="item.nazev"
      class="tile"
      @click="$emit('clicked', item.id)"
    >
      <div class="tile__card">
        <div class="tile__image">
          <div
            class="image"
            v-if="checkThumbnail(item)"
            :style="{
              backgroundImage: 'url(' + item.virkon_thumbnail_url + ')',
            }"
          />
          <div v-else class="image" :style="getImageBackound(item, index)" />
        </div>
        <div class="tile__text">
          <div class="tile__title">{{ item.nazev }}</div>
          <div v-if="!isDermacademy" class="tile__credits">
            <div>ČLK kredity: {{ item.pocet_kreditu }}</div>
            <div v-if="item.pocet_kreditu_virkon > 0">
              Kongres kredity: {{ item.pocet_kreditu_virkon }}
            </div>
            <div class="tile__date">
              <div class="date">
                <div>Dostupný do: {{ formatDate(item.datum_ukonceni) }}</div>
              </div>
            </div>
          </div>
          <div class="tile__description">
            {{ item.popis }}
          </div>
          <div class="tile__read-more">
            {{ readMoreText }} <feather type="arrow-right" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "courses",
  data() {
    return {
      currentIndex: -1,
      hover: false,
    };
  },
  props: {
    items: {
      type: Array,
    },
    readMoreText: {
      type: String,
      default: "Přejít na detail",
    },
  },
  computed: {
    isDermacademy() {
      return this.$route.params.congressId === "3";
    },
  },
  methods: {
    formatDate(date) {
      const event = new Date(date);
      const options = { month: "long", day: "numeric", year: "numeric" };
      return event.toLocaleDateString("cs-CS", options);
    },
    getImageBackound(item) {
      let imageUrl = `https://vz-b3790b7d-a8a.b-cdn.net/${item.bunny_video_id}/thumbnail.jpg`;
      return `background-image: url(${imageUrl})`;
    },
    checkThumbnail(item) {
      return item.virkon_thumbnail_url.indexOf("thumbnail") >= 0;
    },
    linkTo(link) {
      open(link);
      console.log(this.$route.params.congressId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../assets/scss/index.scss";

#tiles {
  display: flex;
  flex-wrap: wrap;

  h1 {
    color: white;
  }

  .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      
    }

    button {
      display: flex;
      align-items: center;

      ::v-deep .feather--home {
        margin-right: 0.5rem;
      }

      @media screen and (max-width: 700px) {
        margin-bottom: 2rem;
      }
    }
  }

  .header-link {
    width: 100%;
    text-align: center;
    margin-top: 3rem;
  }

  .tile {
    padding: 0 12px;
    width: 100%;
    margin-bottom: 2rem;

    .tile__card {
      cursor: pointer;
      overflow: hidden;
      position: relative;
      color: white;
      display: flex;

      &:hover {
        .tile__read-more {
          opacity: 1;
        }
      }

      .tile__image {
        width: 100%;
        max-width: 30rem;
        position: relative;
        margin-right: 2rem;

        .image {
          width: 100%;
          vertical-align: middle;
          height: 16rem;
          background-size: cover;
          background-position: center;
        }
      }

      .tile__text {
        width: 100%;
        padding-right: 2rem;
        position: relative;

        .tile__title {
          font-size: 1.4rem;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }

        .tile__credits {
          margin-bottom: 1.5rem;

          div {
            margin-bottom: 0.5rem;
          }
        }

        .tile__description {
          margin: 0.5rem 0;
          color: gray;
          line-height: 1.5rem;
          &::after {
            content: "";
            display: block;
            background: linear-gradient(
              to bottom,
              rgba(255, 255, 255, 0) 0,
              rgba(24, 24, 24, 1) 75%
            );
            width: 100%;
            height: 6rem;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }
      }

      .tile__read-more {
        background: $dark-bg;
        height: 1rem;
        width: 90%;
        position: absolute;
        bottom: 0;
        z-index: 1;
        padding: 1.1rem 0;
        font-size: 1.2rem;
        color: $primary;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: opacity 350ms linear;

        ::v-deep .feather .feather-arrow-right {
          width: 1.5rem;
          margin-left: 0.3rem;
          stroke-width: 0.1rem;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  #tiles {
    h1 {
      font-size: 2.2rem;
    }
    .tile__card {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 400px) {
  #tiles {
    .tile {
      .tile__card {
        .tile__image {
          .image {
            height: 10rem;
          }
        }
      }
    }
  }
}
</style>
