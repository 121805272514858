import axios  from 'axios'

// insert all your axios logic here
const ax = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

export default {
    install(Vue) {
        Vue.prototype.$axios = ax
    }
}