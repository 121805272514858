<template>
  <div class="page-padding">
    <h1>Novinky</h1>
    <loading-circle v-if="loadingPage" />
    <tiles :items="articles" @clicked="onTileClick" :read-more-text="'Zobrazit novinku'" />
    <error-page v-if="hasError" />
  </div>
</template>

<script>
import tiles from "@/components/tiles";
import errorPage from "../../components/errorPage.vue";
import loadingCircle from "../../components/loadingCircle.vue";
import routeParamMixin from "../../mixins/routeParamMixin";

export default {
  components: { tiles, errorPage, loadingCircle },
  mixins: [ routeParamMixin ],
  
  data() {
    return {
      articles: [],
      hasError: false,
      loadingPage: false,
    };
  },
  computed: {
    congressId() {
      return this.$route.params.congressId;
    },
  },
  mounted() {
    this.fetchArticles();
  },
  methods: {
    async fetchArticles() {
      try {
        this.loadingPage = true;
        ({
          data: {
            articles_modal: { articles: this.articles },
          },
        } = await this.$axios.get(`/congresses/${this.$_congressId}/modals/${this.$_modalId}/articles`));
      } catch {
        this.hasError = true;
      } finally {
        this.loadingPage = false;
      }
    },
    onTileClick(value) {
      this.$router.push({ name: "Article-detail", params: { congressId: this.congressId, detailId: value } });
    },
  },
};
</script>

<style style="scss" scoped>
</style>