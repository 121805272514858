<template>
  <div v-if="showLoginModal" class="login-modal">
    <div class="login-card">
      <feather type="x" class="close-icon" @click="closeLoginModal" />
      <div class="login-card__title">Přihlašení</div>
      <div class="login-card__subtitle">
        <p>
          Pro zobrazení materiálu je nutné být přihlášen. Použijte prosím účet,
          který máte na portále
          <a
            href="https://www.medicinskevzdelavani.cz/registrace/"
            target="_blank"
            >www.medicinskévzdělávání.cz</a
          >
        </p>
      </div>
      <div v-if="showError" class="error-notif">
        Zadané přístupové údaje jsou špatně
      </div>
      <div class="login-card__form">
        <form @submit.prevent="onSubmit">
          <div class="input-wrapper">
            <label for="">Email</label>
            <input type="text" v-model="login.email" />
          </div>
          <div class="input-wrapper">
            <label for="">Heslo</label>
            <input type="password" v-model="login.password" />
          </div>
          <button type="submit">
            <div v-if="!loading">Přihlásit se</div>
            <div v-if="loading" class="loading"></div>
          </button>
          <div class="login-card__footer">
            <div @click="redirectToFortogPassword">Zapomenuté heslo</div>
            <div @click="redirectToRegistration">Registrace</div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-modals",
  props: {
    showLoginModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      login: {
        email: null,
        password: null,
      },
      token: null,
      showError: false,
      loading: false
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        ({
          data: { token: this.token },
        } = await this.$axios.post(
          `https://savana.medicinskevzdelavani.cz/virkon/api/auth`,
          this.login,
          {
            headers: {
              "Api-Key": "5df40322-58d0-4dfb-9997-dda9d639e2db",
            },
          }
        ));
        localStorage.setItem("token", this.token);
        this.$emit('userLogin');
      } catch {
        this.showError = true;
      } finally {
        this.loading = false;
      }
    },
    closeLoginModal() {
      document.body.classList.remove("no-scroll");
      this.$router.go(-1);
    },
    redirectToFortogPassword() {
        window.open('https://savana.medicinskevzdelavani.cz/zabudnute-heslo/?virkonEmbed')
    },
    redirectToRegistration() {
        window.open('https://savana.medicinskevzdelavani.cz/registracia/?virkonEmbed')
    }
  },
};
</script>

<style lang="scss" scoped>
.login-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
  background-color: rgba(12, 12, 12, 0.521);
  backdrop-filter: blur(5px);
  overflow-y: hidden;

  .error-notif {
    margin-bottom: 1rem;
  }

  .login-card {
    max-width: 25rem;
    background: white;
    padding: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    ::v-deep .close-icon {
      cursor: pointer;
      position: absolute;
      right: 1rem;
      top: 1rem;
    }

    .login-card__title {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .login-card__subtitle {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .login-card__form {
      .input-wrapper {
        margin-bottom: 1rem;
      }

      input {
        margin-top: 0.5rem;
        border: 1px solid gray;
        border-radius: 0.3rem;
        padding: 0.5rem;
        height: 2.3rem;

        &:focus {
          outline: none;
        }
      }

      button {
        width: 100%;
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .login-card__footer {
      display: flex;
      justify-content: space-between;
      margin-top: 1rem;
      color: #2a82ad;
      div {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .loading {
    border: 3px solid #f3f3f3;
    border-top: 3px solid #2a82ad;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-left: 1rem;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>